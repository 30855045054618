import React from 'react';

import { PdfViewer } from '../components';

export const PdfView = () => {
  return (
    <div>
      <PdfViewer />
    </div>
  );
};
