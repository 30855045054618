import { Box, Container, Typography } from '@mui/material';

import { AppIcon } from '../components';
import { Category } from '../data';
import { Space } from '../ui';

export const Home = () => {
  return (
    <Container>
      <Box
        sx={{
          my: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            backgroundColor: 'black',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '10px',
          }}
        >
          <Typography variant="h1" component="h1" color="red" sx={{ mb: 2 }}>
            بوترابیه
          </Typography>
        </Box>
        <Space dir="vertical" size={40} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 5,
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <AppIcon name="کتب" type={Category.BOOK} />
          <AppIcon name="شعر" type={Category.POEM} />
          <AppIcon name="نامه" type={Category.LETTER} />
          <AppIcon name="دست نوشته" type={Category.NOTE} />
        </Box>
      </Box>
    </Container>
  );
};
