import { DataList } from './data';

export const poems: DataList[] = [
  {
    id: '56923a6d-ad7d-40ab-be95-5a3b79103c11',
    title: 'امام حسین علیه السلام',
    enabled: true,
  },
  {
    id: '1b492090-f362-44ad-b674-b703903b56af',
    title: 'توحید',
    enabled: true,
  },
  {
    id: 'c71845a5-0ea0-4c6d-9587-a40d548775a8',
    title: 'اهلبیت علیهم السلام',
    enabled: true,
  },
  {
    id: '1c0e3c6b-9f7f-45b9-ac08-87e99b546920',
    title: 'دشمنان اهل بیت علیهم السلام',
    enabled: true,
  },

  {
    id: '8767d6a0-b779-4ed7-9606-6e7cad6c5461',
    title: 'حضرت محمد صلی الله علیه واله',
    enabled: true,
  },
  {
    id: '6e1c9ad6-d650-4339-b084-95bcfa0b4848',
    title: 'حضرت علی علیه السلام',
    enabled: true,
  },
  {
    id: '95b62960-9e1b-4dae-9cbe-160a5ba6d979',
    title: 'حضرت فاطمه زهرا سلام الله علیها',
    enabled: true,
  },
  {
    id: '4caf0af4-c665-4421-9a3a-b4a9dcf5f566',
    title: 'نصایح',
    enabled: true,
  },
  {
    id: 'f58d2001-ab28-4f59-95d2-705881eb3cbd',
    title: 'قرآن و عترت',
    enabled: true,
  },
  {
    id: '7e0a1071-ed84-4642-bd1e-cb3b39245aeb',
    title: 'حضرت علی اکبر علیه السلام',
    enabled: true,
  },
  {
    id: '90eb5cf5-8f06-4fbd-9118-3800f452c190',
    title: 'حضرت زینب سلام الله علیها',
    enabled: true,
  },
  {
    id: 'bcc267c8-dd74-4be8-b3c3-d8573e3ead1b',
    title: 'حضرت اباالفضل علیه السلام',
    enabled: true,
  },
  {
    id: '5a165981-d815-42b5-90ee-63827c0ba706',
    title: 'امام زمان عجل الله',
    enabled: true,
  },
];
