import { Box, Typography } from '@mui/material';
import React from 'react';

import { GeneralItem, Dot } from '../components';
import { getCategoryData, Category } from '../data';

export const Letters = () => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: 'black',
          height: '45px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h5" color="red">
          نامه ها
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          padding: '20px 20px',
          gap: '1px',
        }}
      >
        {getCategoryData(Category.LETTER).map((letter) => (
          <Box
            key={letter.id}
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Dot />
            <GeneralItem key={letter.id} name={letter.title} id={letter.id.toString()} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Letters;
