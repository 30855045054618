import { books } from './books';
import { letters } from './letters';
import { notes } from './notes';
import { poems } from './poems';

export enum Category {
  LETTER = 'letters',
  NOTE = 'notes',
  POEM = 'poems',
  BOOK = 'books',
  NULL = 'null',
}

export interface Context {
  title: string;
  category: Category;
  id: string;
}

export interface DataList {
  id: string;
  title: string;
  enabled: boolean;
}

export const NULL_ID = '00000000-0000-0000-0000-000000000000';

const prepairData = (data: DataList[], category: Category) => {
  return data.reduce<Record<string, Context>>((acc, curr) => {
    if (curr.enabled) {
      acc[curr.id] = { id: curr.id, title: curr.title, category };
    }
    return acc;
  }, {});
};

const data: Record<string, Context> = {
  [NULL_ID]: {
    title: 'Error',
    id: NULL_ID,
    category: Category.NULL,
  },
  ...prepairData(poems, Category.POEM),
  ...prepairData(books, Category.BOOK),
  ...prepairData(letters, Category.LETTER),
  ...prepairData(notes, Category.NOTE),
};

export const getEmptyContext = (): Context => data[NULL_ID];

export const getItemData = (id: string = NULL_ID): Context => {
  return data[id];
};

export const getCategoryData = (category: Exclude<Category, Category.NULL>): Context[] => {
  return Object.values(data).filter((item) => item.category === category);
};
