import { Box } from '@mui/material';
import React, { FunctionComponent } from 'react';

interface DotProps {
  size?: number;
}

export const Dot: FunctionComponent<DotProps> = ({ size = 10 }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'black',
        height: `${size}px`,
        width: `${size}px`,
        borderRadius: '45px',
      }}
    />
  );
};
