import { MenuBook, StickyNote2, LibraryBooks, LocalLibrary } from '@mui/icons-material';
import { Box, IconButton, Typography, Link } from '@mui/material';
import React, { FunctionComponent, PropsWithChildren } from 'react';

import { Category } from '../data';

interface AppIconProps {
  name: string;
  type: Category;
}

export const AppIcon: FunctionComponent<AppIconProps> = ({ name, type }) => {
  return (
    <RenderLink type={type}>
      <Box
        style={{
          border: 'solid 1px black',
          borderRadius: '7px',
          padding: '0 10px 10px',
        }}
      >
        <IconButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <RenderIcon type={type} />
            <Typography variant="18700" color="red">
              {name}
            </Typography>
          </Box>
        </IconButton>
      </Box>
    </RenderLink>
  );
};

export default AppIcon;

function RenderIcon({ type }: { type: Category }) {
  const style = { height: '100px', width: '100px', color: 'black' };
  switch (type) {
    case Category.BOOK:
      return <MenuBook sx={style} />;
    case Category.LETTER:
      return <LibraryBooks sx={style} />;
    case Category.NOTE:
      return <StickyNote2 sx={style} />;
    case Category.POEM:
      return <LocalLibrary sx={style} />;
    default:
      return <MenuBook sx={style} />;
  }
}

export const RenderLink: FunctionComponent<PropsWithChildren<{ type: Category }>> = ({
  type,
  children,
}) => {
  let href = undefined;
  switch (type) {
    case Category.BOOK:
      href = '/books';
      break;
    case Category.NOTE:
      href = '/notes';
      break;
    case Category.POEM:
      href = '/poems';
      break;
    case Category.LETTER:
      href = '/letters';
      break;
    default:
      break;
  }
  return <Link href={href}>{children}</Link>;
};
